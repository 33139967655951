<template>
    <div class="dossier-selector">
        <BaseAccordion
            :scroll-into-view="false"
            :items="dossierStore.themes"
            class="base-accordion--lighter"
        >
            <template #title="{ item: theme }">
                {{ theme.title }}
                <BaseLabel>
                    {{ getNumberOfSelectedDossiersConnectedToTheme(theme.id) }} /
                    {{ getDossiersConnectedToTheme(theme.id).length }}
                    <slot name="selected">
                        geselecteerd
                    </slot>
                </BaseLabel>
            </template>

            <template #default="{ item: theme }">
                <ButtonList>
                    <li
                        v-for="dossier in getDossiersConnectedToTheme(theme.id)"
                        :key="dossier.id"
                    >
                        <slot
                            name="dossier-button"
                            :is-selected="_selectedDossierIds.includes(parseInt(dossier.id, 10))"
                            :dossier="dossier"
                        />
                    </li>
                </ButtonList>

                <p
                    v-if="!!$slots['select-theme-button'] && getNumberOfSelectedDossiersConnectedToTheme(theme.id) !== getDossiersConnectedToTheme(theme.id).length"
                    style="margin-top: 1.5rem;"
                >
                    <slot
                        name="select-theme-button"
                        :theme="theme"
                        :dossier-ids="getDossiersConnectedToTheme(theme.id).map(dossier => dossier.id)"
                    />
                </p>
            </template>
        </BaseAccordion>
    </div>
</template>

<script setup>
import { useDossierStore } from '~/store/dossiers';
import { defineProps } from 'vue';

const props = defineProps({
    selectedDossierIds: {
        type: [Array, Object],
        default: () => []
    },
    onSelectionChange: {
        type: Function,
        default: () => {}
    }
});

const _selectedDossierIds = ref(props.selectedDossierIds);

defineEmits([
    'select-theme'
]);

const dossierStore = useDossierStore();

const getDossiersConnectedToTheme = (themeId) => {
    if (!dossierStore.dossiers || dossierStore.dossiers.length === 0 || !themeId) {
        return [];
    }

    return dossierStore.dossiers.filter(dossier => dossier.parent && dossier.parent.id === themeId);
};

const getNumberOfSelectedDossiersConnectedToTheme = (themeId) => {
    return getDossiersConnectedToTheme(themeId)
        .filter(dossier => toValue(_selectedDossierIds).includes(parseInt(dossier.id, 10)))
        .length;
};
</script>

<style lang="less" src="./DossierSelector.less"></style>
